import request from './request'

export function adminList(params){
    return request({
        method:'POST',
        url:'/Admin/adminList',
        data:params,
    })
}
export function pullRoleList(params){
    return request({
        method:'POST',
        url:'/Admin/pullRoleList',
        data:params,
    })
}
export function roleList(params){
    return request({
        method:'POST',
        url:'/Admin/roleList',
        data:params,
    })
}
export function roleInfo(params){
    return request({
        method:'POST',
        url:'/Admin/roleInfo',
        data:params,
    })
}
export function passagewayAcc(params){
    return request({
        method:'POST',
        url:'/passageway/passagewayAcc',
        data:params,
    })
}
export function passagewayAccEdit(params){
    return request({
        method:'POST',
        url:'/passageway/passagewayAccEdit',
        data:params,
    })
}
export function addEditRole(params){
    return request({
        method:'POST',
        url:'/Admin/addEditRole',
        data:params,
    })
}
export function addEditAdmin(params){
    return request({
        method:'POST',
        url:'/Admin/addEditAdmin',
        data:params,
    })
}
export function adminLog(params){
    return request({
        method:'POST',
        url:'/Admin/adminLog',
        data:params,
    })
}

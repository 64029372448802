<template>
  <div>
    <GlobalInfoBar title="运营通道列表"/>
    <GlobalChunk
        :padding="[20,20,20,20]">
      <GlobalForm
          :form-item-list="formItemList"
          :inline="true"
          round
          @handleConfirm="query"
          confirmBtnName="查询">
<!--        <div style="display: flex" slot="AddSlot">-->
<!--          <el-button type="primary" @click="addChannel" size="small" icon="el-icon-plus">添加通道</el-button>-->
<!--        </div>-->
      </GlobalForm>

      <GlobalTable
          ref="GlobalTable"
          :columns="tableColumns"
          :data="tableData"
          :currentPage="curPage"
          :total="totalElements"
          @handleCurrentChange="currentChange">

        <el-table-column label="是否开启手机验证" slot="is_sms" align="center">
          <template slot-scope="{ row }">
            {{ row.is_sms === 1 ? '是' : '否' }}
          </template>
        </el-table-column>

        <el-table-column label="取货方式" slot="pickup_mode" align="center" width="200px">
          <template slot-scope="{ row }">
            <el-button-group>
              <el-button size="mini" disabled class="combine-btn" :type="row.pickup_mode===1?'primary':'plain'" @click="changePickUpMethod(row,1)">到店焕新</el-button>
              <el-button size="mini" disabled class="combine-btn" :type="row.pickup_mode===2?'primary':'plain'" @click="changePickUpMethod(row,2)">快递上门</el-button>
              <el-button size="mini" disabled class="combine-btn" :type="row.pickup_mode===0?'primary':'plain'" @click="changePickUpMethod(row,0)">全部方式</el-button>
            </el-button-group>
          </template>
        </el-table-column>

        <el-table-column label="启用/禁用" slot="is_open" align="center">
          <template slot-scope="{ row }">
<!--            <el-switch v-model="row.is_open" :active-value="1" :inactive-value="0" @change="switchChange(row)"></el-switch>-->
            <span>{{row.is_open?'是':'否'}}</span>
          </template>
        </el-table-column>

        <el-table-column label="机型设置" slot="setPhoneBuyPrice" align="center">
          <template slot-scope="{ row }">
            <a href="javascript:" @click="setPhoneBuyPrice(row)">设置</a>
          </template>
        </el-table-column>

<!--        <el-table-column label="账户设置" slot="accountSetting" align="center">-->
<!--          <template slot-scope="{ row }">-->
<!--            <a href="javascript:" @click="editAccount(row)">设置</a>-->
<!--          </template>-->
<!--        </el-table-column>-->

<!--        <el-table-column label="操作" slot="operation" align="center">-->
<!--          <template slot-scope="{ row }">-->
<!--            <el-button type="primary" size="small" @click="editRow(row)">编辑</el-button>-->
<!--          </template>-->
<!--        </el-table-column>-->

      </GlobalTable>
    </GlobalChunk>
    <el-dialog :title="dialog.type+'提示'" :visible.sync="dialog.isShow" width="500px" top="200px">
      <div class="dialog-content" v-if="dialog.type==='开启'">
        <p>启用后该通道可通过H5链接进入旧机焕新，</p>
        <p>是否确认启用该运营通道？</p>
      </div>
      <template v-else>
        <div class="dialog-content close">
          <p>禁用后该通道生成的H5链接将不可用，</p>
          <p>是否确认禁用该运营通道？</p></div>
      </template>
      <div class="dialog-btns">
        <el-button type="primary" class="cancel" plain size="mini" @click="switchCancel">取消</el-button>
        <el-button type="primary" size="mini" @click="switchConfirm">确定</el-button>
      </div>
    </el-dialog>


    <el-dialog title="编辑账户" :visible.sync="accountDialog.isShow" width="600px" class="account-dialog">
      <div class="dialog-notice">{{ accountDialog.notice }}</div>
      <el-form ref="dialogForm" :model="accountDialog.form" label-width="80px" size="small" class="dialog-form"
               :rules="accountDialog.rules">
        <el-form-item prop="account" label="账户名称">
          <el-input v-model="accountDialog.form.account" placeholder="请输入账户名称" maxlength="15"></el-input>
        </el-form-item>

        <el-form-item prop="password" label="账户密码">
          <el-input v-model="accountDialog.form.password" placeholder="请输入账户密码"
                    show-word-limit class="password-input"></el-input>
        </el-form-item>

        <el-form-item prop="act" label="菜单权限" class="">
          <el-tree
              ref="tree"
              :check-strictly="true"
              @check="treeCheck"
              :data="accountDialog.menu"
              show-checkbox
              :default-expanded-keys="accountDialog.form.act"
              node-key="id"
              check-on-click-node
              :props="accountDialog.defaultTreeProps">
          </el-tree>
        </el-form-item>
      </el-form>

      <div class="dialog-btns">
        <el-button type="primary" size="small" @click="accountEditConfirm">确认{{ accountDialog.type }}</el-button>
        <el-button type="primary" size="small" plain @click="accountDialog.isShow=false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import axios from 'axios'
import {passagewayHandle, passagewayList, phonePriceHandle} from "../../../utils/request/changePhone";
import {passagewayAcc, passagewayAccEdit} from "../../../utils/request/systemManage";

export default {
  name: "index",
  data() {
    let validateAct = (rule, value, callback) => {
      this.$nextTick(()=>{
        let act=this.accountDialog.form.act
        if (!(act && act.length)) {
          callback('请选择菜单权限')
        }else {
          callback()
        }
      })
    }
    return {
      formItemList: [{
        key: 'pass_name',
        type: 'input',
        labelName: '通道名称:',
        placeholder: '请输入通道名称'
      }, {
        key: 'is_sms',
        idKey: 'id',
        labelKey: 'name',
        type: 'select',
        labelName: '是否开启手机验证:',
        option: [{name: '是', id: 1}, {name: '否', id: 0}],
        placeholder: '请选择是否开启手机验证'
      }],
      pass_name: '',
      is_sms: '',
      tableColumns: [
        {label: "所属合伙人", prop: "partner_name"},
        {label: "通道名称", prop: "pass_name"},
        {label: "通道链接", prop: "pass_url"},
        {slotName: "is_sms"},
        {slotName: "pickup_mode"},
        {slotName: "is_open"},
        {slotName: "setPhoneBuyPrice"},
        {slotName: "accountSetting"},
        {slotName: "operation"},
      ],
      tableData: [],
      curPage: 1,
      totalElements: 0,
      dialog: {
        isShow: false,
        type: '开启',
        row: null
      },
      accountDialog:{
        isShow: false,
        form: {
          account: '',
          password: '',
          act: []
        },
        rules: {
          account: [
            {required: true, message: '请填写角色名称', trigger: 'blur'},
            // {validator: validateRoleName, trigger: 'blur'},
          ],
          act: [
            {validator: validateAct, trigger: 'change'}
          ]
        },
        menu: [],
        defaultTreeProps: {
          children: 'children',
          label: 'name'
        },
        curPId:''
      },
    };
  },
  mounted() {
    this.getList()
  },
  methods: {

    currentChange(page) {
      this.curPage = page
      this.getList()
    },
    query(data) {
      this.pass_name = data.pass_name
      this.is_sms = data.is_sms
      this.getList();
    },
    editRow(row) {
      this.$router.push({
        path: 'channelOperation',
        query: {pass_id:row.pass_id}
      })
    },
    deleteRow(row) {
      // storeHandle({
      //   store_id: row.store_id,
      //   type: 2,
      // }).then(data => {
      //   this.getList()
      //   this.$message.success(data && data.msg || '删除成功')
      // }).catch(err => {
      //   this.$message.error(err && err.msg || '删除失败')
      //   console.error(err)
      // })
    },
    getList() {
      passagewayList({
        page: this.curPage,
        limit: 10,
        pass_name: this.pass_name,
        is_sms: this.is_sms
      }).then(res => {
        this.tableData = res.data || [];
        this.totalElements = res.count || 0
      })
    },
    addChannel() {
      this.$router.push({
        path: 'channelOperation'
      })
    },
    switchChange(row) {
      this.dialog.row = row
      this.dialog.isShow = true

      this.dialog.type = row.is_open === 1 ? '开启' : '关闭'
    },
    switchCancel() {
      this.dialog.isShow = false
      this.getList()
    },
    switchConfirm() {
      passagewayHandle({
        pass_id: this.dialog.row.pass_id,
        type: 1,
        field: 'is_open',
        value:this.dialog.row.is_open
      }).then(data=>{
        this.$message.success(data&&data.msg||this.dialog.type+'成功')
      }).catch(err=>{
        console.error(err)
      }).finally(()=>{
        this.dialog.isShow = false
        this.getList()
      })
    },
    setPhoneBuyPrice(row) {
      this.$router.push({
        path:'newPhoneBuyPrice',
        query:{
          pass_id:row.pass_id
        }
      })
    },
    changePickUpMethod(row,pickup_mode) {
      passagewayHandle({
        pass_id: row.pass_id,
        type: 1,
        field: 'pickup_mode',
        value:pickup_mode
      }).then(data=>{
        this.$message.success(data&&data.msg||this.dialog.type+'成功')
      }).catch(err=>{
        console.error(err)
      }).finally(()=>{
        this.getList()
      })
    },
    editAccount(row) {
      this.accountDialog.isShow = true
      this.accountDialog.form = row
      this.accountDialog.form.account = row.account||''
      this.accountDialog.form.password = ''
      let act = row.act_list&&row.act_list.split(',').map(Number)||[]
      this.accountDialog.form.act = act
      this.$nextTick(() => {
        this.$refs.dialogForm.clearValidate()
        passagewayAcc({pass_id: row.pass_id}).then(res => {
          this.accountDialog.menu = res.data.menu
          this.$refs.tree.setCheckedKeys(act)
        })
      })
    },
    treeCheck(data, node) {
      this.accountDialog.form.act = node.checkedKeys
    },
    accountEditConfirm() {
      this.$refs.dialogForm.validate(valid=>{
        if (valid) {
          passagewayAccEdit({
            pass_id:this.accountDialog.form.pass_id,
            account:this.accountDialog.form.account,
            password:this.accountDialog.form.password,
            act:this.accountDialog.form.act
          }).then(res=>{
            this.$message.success(res.msg||'编辑成功')
          }).catch(err=>{
            this.$message.success(err.msg||'编辑失败')
          }).finally(()=>{
            this.accountDialog.isShow=false
            this.getList()
          })
        }
      })
    }
  }
}
</script>

<style scoped>
::v-deep .el-form-item__label {
  width: 10em !important;
}

.dialog-content p {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}
.dialog-content.close {
  color: red;
}

.dialog-btns {
  margin: 20px 0;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  padding-right: 42px;

}

.dialog-btns .cancel {
  margin-right: 20px;
}
.combine-btn{
  padding: 6px 5px;
}
.account-dialog::v-deep .el-input,
.account-dialog::v-deep .el-tree{
  width: 300px;
}
@font-face {
  font-family: pwd;
  src: url("PasswordEntry.ttf");
}
.password-input::v-deep .el-input__inner{
  font-family: pwd,serif!important;
}
</style>
<style>
.el-tree{
  height: 200px;
  overflow:auto;
}
.el-tree-node__content{
  margin-left: -24px;
}
</style>
